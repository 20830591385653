<script>
import jwt_decode from 'jwt-decode';

export default {
   name: "isReator",
   methods: {
      isRealtor() {
         let userCompanies = jwt_decode(localStorage.getItem('access_token')).roles.companies;
         let isRealtor = false;
         userCompanies.forEach(companies => {
            if (companies.group_id === 2) {
               for (const company of companies.companies) {
                  if (company === localStorage.getItem('companyId')) {
                     isRealtor = true;
                  }
               }
            }
         })
         return isRealtor
      },
   }
}
</script>

<style scoped>

</style>