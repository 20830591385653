<template>
    <div class="row gutters">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="d-flex w-100 flex-row justify-content-end mb-3 pr-1" v-if="filter">
                <div class="btn btn-primary" id="filter" data-toggle="modal" data-target="#customModalTwo"
                     @click="$emit('filter')">
                    Filtro
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th class="text-center" v-for="(title, index) in titles" :key="index">{{ title }}</th>
                                <th v-if="showAction" class="text-center">
                                    <div class="d-flex justify-content-center" v-if="hasIconExport">
                                        <p class="mr-2">Ações</p>
                                        <button class="btn btn-primary " style="min-width: 40px" title="Gerar PDF"
                                                @click="$emit('exportPdf')"><i class="material-icons-outlined">picture_as_pdf</i>
                                        </button>
                                    </div>
                                    <p v-else>Ações</p>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <slot name="tableBody"></slot>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableComponent',
    props: {
        filter: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: true,
        },
        titles: {
            required: true,
        },
        hasIconExport: {
            type: Boolean,
           default: false,
        }
    },
}
</script>

<style scoped>

</style>
