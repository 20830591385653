<template>
    <div>
        <div class="modal-row2 modal-height-max">
            <div class="modal-body">
                <div class="row gutters mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="corretor_name">Corretor: </label>
                            <input type="text" id="corretor_name" v-model="iten.corretor_name"
                                   placeholder="Corretor"
                                   disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="cpf_cnpj_corretor">CPF/CNPJ: </label>
                            <input type="text" id="cpf_cnpj_corretor" v-model="iten.cpf_cnpj_corretor"
                                   placeholder="CPF/CNPJ" disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="contrato">Contrato: </label>
                            <input type="text" id="contrato" v-model="iten.contrato"
                                   placeholder="Contrato" disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="comprador">Comprador: </label>
                            <input type="text" id="comprador"
                                   placeholder="Comprador" disabled
                                   v-model="iten.comprador"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6">
                        <div class="form-group">
                            <label for="situacao">Situacao: </label>
                            <input type="text" id="situacao" v-model="iten.situacao"
                                   placeholder="Situação" disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6">
                        <div class="form-group">
                            <label for="quadra">Quadra: </label>
                            <input type="text" id="quadra" v-model="iten.quadra"
                                   placeholder="Quadra" disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6">
                        <div class="form-group">
                            <label for="lote">Lote: </label>
                            <input type="text" id="lote" v-model="iten.lote"
                                   placeholder="Lote" disabled
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="valor_entrada">Valor Entrada: </label>
                            <input type="text" id="valor_entrada" v-model="iten.valor_entrada"
                                   placeholder="Valor Entrada" disabled
                                   v-money3="config"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="valor_corretagem">Corretagem: </label>
                            <input type="text" id="valor_corretagem" v-model="iten.valor_corretagem"
                                   placeholder="Corretagem" disabled
                                   v-money3="config"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="valor_pago_corretagem">Valor Pago: </label>
                            <input type="text" id="valor_pago_corretagem" v-model="iten.valor_pago_corretagem"
                                   placeholder="Valor Pago" disabled
                                   v-money3="config"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="data_movimentacao">Data Movimentação: </label>
                            <input type="date" id="data_movimentacao"  disabled
                                   v-model="iten.data_movimentacao"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="data_credito_entrada">Data Credito Entrada: </label>
                            <input type="date" id="data_credito_entrada" disabled
                                   v-model="iten.data_credito_entrada"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                        <div class="form-group">
                            <label for="data_credito">Data Credito: </label>
                            <input type="date" id="data_credito" disabled
                                   v-model="iten.data_credito"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6">
                        <div class="form-group">
                            <label for="data_pagamento">Data Pagamento: </label>
                            <input type="date" id="data_pagamento" disabled
                                   v-model="iten.data_pagamento"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6">
                        <div class="form-group">
                            <label for="data_vencimento">Vencimento: </label>
                            <input type="date" id="data_vencimento" disabled
                                   v-model="iten.data_vencimento"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-6 d-flex">
                        <div class="custom-control custom-switch align-self-center">
                            <input type="checkbox" class="custom-control-input" id="assinado"
                                   v-model="iten.assinado" disabled >
                            <label  class="custom-control-label" for="assinado">Assinado</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="descricao">Descrição: </label>
                            <textarea class="form-control" disabled id="descricao" rows="3" v-model="iten.descricao">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer right">
            <button type="button" class="btn btn-primary mr-1" @click="closeModal">{{
                    t('ACTIONS.CLOSE')
                }}
            </button>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {Money3Directive} from 'v-money3';

export default {
    name: "viewComissionComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    directives: {money3: Money3Directive},
    props: {
        itenIndex:{
            required: true,
            type: Object
        }
    },
    mounted() {
        if (this.itenIndex) this.iten = this.itenIndex;
    },
    watch: {
        itenIndex() {
            this.iten = this.itenIndex;
        }
    },
    data() {
        return {
            iten: {},
            config: {
                min: 0,
                masked: true,
                precision: 2,
                allowBlank: false,
                disableNegative: true,
                suffix: '',
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                disabled: false,
                max: 100000000000,
                minimumNumberOfCharacters: 1,
            },
        }
    },
    methods: {
        closeModal(){
            document.getElementById('closeX')?.click();
        }
    }
}
</script>

<style scoped>

</style>