import {http} from './config';

export default {
    loadAllotmentBd: () => {
        return http.get('api/v1/loteamentos/listar', {params:{'distict-configuracao-bd': true}});
    },
    index: (allotmentId, filter = {}, page, exportPdf = false) => {
        let situacao = "";
        let vencimento_inicial = "";
        let vencimento_final = "";
        let cpf_cnpj_ou_nome = "";
        if (filter.situacao) situacao = filter.situacao;
        if (filter.vencimento_inicial) vencimento_inicial = filter.vencimento_inicial;
        if (filter.vencimento_final) vencimento_final = filter.vencimento_final;
        if (filter.cpf_cnpj_ou_nome) cpf_cnpj_ou_nome = filter.cpf_cnpj_ou_nome;
        return http.get(`api/v1/comissoes?page=${page}`, {
            headers: {
                "allotment-id": allotmentId,
            },
            params: {
                "situacao": situacao,
                "vencimento_inicial": vencimento_inicial,
                "vencimento_final": vencimento_final,
                "cpf_cnpj_ou_nome": cpf_cnpj_ou_nome,
                "export": exportPdf,
            }
        });
    }
}